import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

export default function CTA() {
  return (
    <Stack
      id="heroSection"
      minH={"100vh"}
      direction={{ base: "column", md: "row" }}
      bg={useColorModeValue("wezeshaWhite", "wezeshaBlack")}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
            fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
            fontFamily={"heading"}
          >
            <Text
              as={"span"}
              position={"relative"}
              color={useColorModeValue("wezeshaBlack", "wezeshaWhite")}
              _after={{
                content: "''",
                width: "full",
                height: useBreakpointValue({ base: "20%", md: "30%" }),
                position: "absolute",
                bottom: 1,
                left: 0,
                // bg: "blue.400",
                // zIndex: -1,
              }}
            >
              Optimize Your
            </Text>
            <br />{" "}
            <Text
              bgGradient={"linear(to-r, wezeshaSky, wezeshaBlue)"}
              bgClip={"text"}
              as={"span"}
            >
              ISP Business
            </Text>{" "}
          </Heading>
          <Text
            fontSize={{ base: "md", lg: "lg" }}
            color={useColorModeValue("wezeshaBlack", "wezeshaWhite")}
            fontWeight={200}
            fontFamily={"heading"}
          >
            Built out of the growing necessities of the internet service provider market, 
            we well understand what it takes to succeed and want to share our approach with you.
          </Text>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Button
              rounded={"full"}
              bg={useColorModeValue("wezeshaBlack", "wezeshaWhite")}
              color={useColorModeValue("wezeshaWhite", "wezeshaBlack")}
              _hover={{
                bg: useColorModeValue("wezeshaBlue", "wezeshaSky"),
                color: useColorModeValue("wezeshaWhite", "wezeshaBlack"),
              }}
              px={8}
            >
              Request Demo
            </Button>
            <Button
              as={"a"}
              border={"1px solid"}
              borderColor={useColorModeValue("wezeshaBlack", "wezeshaWhite")}
              rounded={"full"}
              color={useColorModeValue("wezeshaBlack", "wezeshaWhite")}
              bg={useColorModeValue("wezeshaWhite", "wezeshaBlack")}
              _hover={{
                bgGradient: "linear(to-r, wezeshaSky, wezeshaBlue)",
                borderColor: "transparent",
                color: useColorModeValue("wezeshaWhite", "wezeshaBlack"),
              }}
              href={"https://wezeshanet.com/client/register"}
              px={8}
            >
              Sign Up
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={"CTA"}
          objectFit={"cover"}
          borderRadius={"70px"}
          src={
            "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
          }
        />
      </Flex>
    </Stack>
  );
}
