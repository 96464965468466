import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { FaMoneyBill } from "react-icons/fa";
import {
  FcBullish,
  FcBusiness,
  FcCustomerSupport,
  FcMoneyTransfer,
  FcPackage,
  FcSpeaker
} from "react-icons/fc";

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
  href: string;
}

const Card = ({ heading, description, icon, href }: CardProps) => {
  return (
    <Box
      maxW={{ base: "full", md: "400px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
        <Button variant={"link"} colorScheme={"blue"} size={"sm"}>
          Learn more
        </Button>
      </Stack>
    </Box>
  );
};

export default function About() {
  return (
    <Box p={4} id="aboutSection">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={{ base: "2xl", sm: "4xl" }} fontWeight={"bold"}>
          Features
        </Heading>
        <Text color={"gray.600"} fontSize={{ base: "sm", sm: "lg" }}>
          Optimize your workforce and increase revenue with
          high quality and uninterrupted service
        </Text>
      </Stack>

      <Container maxW={"1300px"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={"Billing Management"}
            icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
            description={
              "Automated invoicing and payment processing ensures that payments and authorizations happen instantly to enhance the customer experience."
            }
            href={"#"}
          />
          <Card
            heading={"Network Management"}
            icon={<Icon as={FcBullish} w={10} h={10} />}
            description={
              "Monitor and manage the state of your network devices and the health status of your network from wherever you are."
            }
            href={"#"}
          />
          <Card
            heading={"Package Management"}
            icon={<Icon as={FcPackage} w={10} h={10} />}
            description={
              "Optimize the way you serve your customers with a streamlined package management platform."
            }
            href={"#"}
          />
          <Card
            heading={"CRM"}
            icon={<Icon as={FcCustomerSupport} w={10} h={10}/>}
            description={"Manage your customers and improve the quality of your service with integrated support and helpdesk system. A dedicated customer portal ensures that your customers can reach you on the go."}
            href={"#"}
          />
          <Card
            heading={"Team Management"}
            icon={<Icon as={FcBusiness} w={10} h={10}/>}
            description={"Optimize your team's performance by managing how each member interacts with the platform. Improve accountability and enhance overall efficiency by focusing on what matters."}
            href={"#"}
          />
          <Card
            heading={"Sales & Marketing"}
            icon={<Icon as={FcSpeaker} w={10} h={10}/>}
            description={"Run targeted campaigns to your client base and maintain a record of repeat customers."}
            href={"#"}
          />

        </Flex>
      </Container>
    </Box>
  );
}
