import { Box, useColorModeValue } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import CTA from "../sections/cta";
import About from "../sections/About";
import Pricing from "../sections/Pricing";
import Footer from "../sections/Footer";

const Home = () => {
  return (
    <Box bg={useColorModeValue("wezeshaWhite", "wezeshaBlack")}>
      <Navbar />
      <CTA />
      <Box m={10} />
      <About />
      <Box m={10} />
      <Pricing />
      <Box m={10} />
      <Footer />
    </Box>
  );
};

export default Home;
