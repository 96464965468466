import { extendTheme } from "@chakra-ui/react";
import '@fontsource/raleway/400.css'
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";
import '@fontsource/kumbh-sans/400.css'
import "@fontsource/kumbh-sans/600.css";
import "@fontsource/kumbh-sans/800.css";

const fonts = {
  heading: `'Kumbh Sans', sans-serif`,
  body: `'Kumbh Sans', sans-serif`,
};

const breakpoints = {
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "80em",
};

const theme = extendTheme({
  semanticTokens: {
    colors: {
      text: {
        default: "#16161D",
        _dark: "#ade3b8",
      },
      heroGradientStart: {
        default: "#7928CA",
        _dark: "#e3a7f9",
      },
      heroGradientEnd: {
        default: "#FF0080",
        _dark: "#fbec8f",
      },
    },
    radii: {
      button: "12px",
    },
  },
  colors: {
    wezeshaBlack: "#0f0f0f",
    wezeshaBlue: "#45a0f5",
    wezeshaSky: "#26e3c2",
    wezeshaWhite: "#fcfcfc",
  },
  fonts,
  breakpoints,
});

export default theme;
